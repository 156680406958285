
<template>
    <div class="Pool">
        <div class="poolContentBox">
            <!-- <div class="connectBoxW allPools">
                <h2 class="title">Your Liquidity</h2>
                <ul class="titleUl">
                    <li>NAME</li>
                    <li>Liquidity</li>
                    <li class="allYourLiquidity">
                        Your Liquidity (without farm)
                    </li>
                    <li>APR</li>
                    <li></li>
                </ul>
                <ul class="poolList">
                    <li class="item" v-for="(item,index) in allpoolPagelist" :key="index">
                        <div :class="item.show ? 'activeItem' : ''">
                            <div class="pcinfo">
                                <p class="name">
                                    <span>
                                        <img :src="item.token1.logoURI" alt="">
                                        <img :src="item.token0.logoURI" alt="">
                                    </span>
                                    {{item.token0.symbol}}-{{item.token1.symbol}}
                                </p>
                                <p class="liquidity">$ {{item.liquidity.toFixed(0)}}</p>
                                <p class="yourLiquidity">$ {{ item.yourliquidity == 0 ? '0.000000' : item.yourliquidity.toFixed(8) }}</p>
                                <p class="apr">{{item.apr.toFixed(2)}}%  </p>
                                <p class="btn">
                                    <span @click="goSwap">Manage Liquidity</span>
                                </p>
                            </div>
                            <div class="iphoneinfo" @click="clickYourItem(index)">
                                <p class="name">
                                    <span class="names">
                                        <span class="lpLogo">
                                            <img :src="item.token1.logoURI" alt="">
                                            <img :src="item.token0.logoURI" alt="">
                                        </span>
                                        {{item.token0.symbol}}-{{item.token1.symbol}}
                                    </span>
                                    
                                </p>
                                <p class="liquidity">
                                    <span>Liquidity</span>
                                    <span>$ {{item.liquidity}}</span>
                                </p>
                                <p class="apr">
                                    <span>APR</span>
                                    <span>{{item.apr}}%</span>
                                </p>
                            </div>
                            <div v-if="item.show" class="btns">
                                <a href="">Add Liquidity</a>
                                <a v-if="item.yourliquidity != 0" href="">Remove Liquidity</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div> -->
            <div class="connectBoxW allPools">
                <h2 class="title">All Pools</h2>
                <ul class="titleUl">
                    <li>NAME</li>
                    <li>Liquidity</li>
                    <li class="allYourLiquidity">
                        Your Liquidity (without farm)
                        <!-- <i class="el-icon-question"><span>Your Liquidity (without farm)</span></i> -->
                        
                    </li>
                    <li>APR</li>
                    <li></li>
                </ul>
                <ul class="poolList">
                    <li class="item" v-for="(item,index) in allpoolPagelist" :key="index">
                        <div :class="item.show ? 'activeItem' : ''">
                            <div class="pcinfo">
                                <p class="name">
                                    <span>
                                        <img :src="item.token1.logoURI" alt="">
                                        <img :src="item.token0.logoURI" alt="">
                                    </span>
                                    {{item.token0.symbol}}-{{item.token1.symbol}}
                                </p>
                                <p class="liquidity">$ {{item.liquidity.toFixed(0)}}</p>
                                <p class="yourLiquidity">$ {{ item.yourliquidity == 0 ? '0.000000' : item.yourliquidity.toFixed(8) }}</p>
                                <p class="apr">{{item.apr.toFixed(2)}}%  </p>
                                <p class="btn">
                                    <span @click="goSwap">Manage Liquidity</span>
                                </p>
                            </div>
                            <div class="iphoneinfo" @click="clickYourItem(index)">
                                <p class="name">
                                    <span class="names">
                                        <span class="lpLogo">
                                            <img :src="item.token1.logoURI" alt="">
                                            <img :src="item.token0.logoURI" alt="">
                                        </span>
                                        {{item.token0.symbol}}-{{item.token1.symbol}}
                                    </span>
                                    
                                </p>
                                <p class="liquidity">
                                    <span>Liquidity</span>
                                    <span>$ {{item.liquidity}}</span>
                                </p>
                                <p class="apr">
                                    <span>APR</span>
                                    <span>{{item.apr}}%</span>
                                </p>
                                <!-- <p class="show"><i :class=" item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></p> -->
                            </div>
                            <div v-if="item.show" class="btns">
                                <a href="">Add Liquidity</a>
                                <a v-if="item.yourliquidity != 0" href="">Remove Liquidity</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="pageBox" v-if="zksyncpoollist.length > 10">
                    <div class="block">
                        <el-pagination
                            layout="prev, pager, next"
                            :page-size="pagesize"
                            :total="zksyncpoollist.length"
                            @current-change="changePage">
                        </el-pagination>
                    </div>
                </div> -->
            </div>
        </div>
        <v-Footer></v-Footer>
    </div>
</template>

<script>
import { getAllPairsLength,getAllPairs,getPairToken0,getPairToken1,getTokenSymbol } from '@/utils/space.js'
export default {
    name: "Pool",
    data() {
        return {
            allpoolPagelist:[
                {
                    show:false,
                    liquidity:102043,
                    yourliquidity:0,
                    apr:339.81,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000003.png',
                        symbol:'USDB'
                    }
                },{
                    show:false,
                    liquidity:1029,
                    yourliquidity:0,
                    apr:95.20,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'YES'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C.png',
                        symbol:'WETH'
                    }
                },{
                    show:false,
                    liquidity:631,
                    yourliquidity:0,
                    apr:43.42,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0xA4C7aA67189EC5623121c6C94Ec757DfeD932D4B.png',
                        symbol:'MIA'
                    }
                },{
                    show:false,
                    liquidity:397,
                    yourliquidity:0,
                    apr:141.57,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x73c369F61c90f03eb0Dd172e95c90208A28dC5bc.png',
                        symbol:'OLE'
                    }
                },{
                    show:false,
                    liquidity:793,
                    yourliquidity:0,
                    apr:166.32,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x216A5a1135A9dab49FA9Ad865E0f22FE22b5630A.png',
                        symbol:'PUMP'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    }
                },{
                    show:false,
                    liquidity:554,
                    yourliquidity:0,
                    apr:88.81,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x5FE8534a6F96cb01261Bd96e98c17C2c1Cab3204.png',
                        symbol:'BAJA'
                    }
                },{
                    show:false,
                    liquidity:1183,
                    yourliquidity:0,
                    apr:39.99,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0xB582Dc28968c725D2868130752aFa0c13EbF9b1a.png',
                        symbol:'BEPE'
                    }
                },{
                    show:false,
                    liquidity:601,
                    yourliquidity:0,
                    apr:66.77,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0xCa84812E477eE5a96a92328689D8Ce2589aB6FfD.png',
                        symbol:'ALIEN'
                    }
                },{
                    show:false,
                    liquidity:825,
                    yourliquidity:0,
                    apr:29.43,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x42E12D42b3d6C4A74a88A61063856756Ea2DB357.png',
                        symbol:'ORBIT'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    }
                },{
                    show:false,
                    liquidity:308,
                    yourliquidity:0,
                    apr:91.84,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x0B4d0ee29857c3961b380d4ec138EA5814E346b9.png',
                        symbol:'PACM'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    }
                },{
                    show:false,
                    liquidity:445,
                    yourliquidity:0,
                    apr:39.14,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0xd582879453337BD149Ae53EC2092B0af5281d1D7.png',
                        symbol:'GLORY'
                    }
                },{
                    show:false,
                    liquidity:1522,
                    yourliquidity:0,
                    apr:188.91,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x764933fbAd8f5D04Ccd088602096655c2ED9879F.png',
                        symbol:'AI'
                    }
                },{
                    show:false,
                    liquidity:829,
                    yourliquidity:0,
                    apr:55.64,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0xb9dfCd4CF589bB8090569cb52FaC1b88Dbe4981F.png',
                        symbol:'BAG'
                    }
                },{
                    show:false,
                    liquidity:551,
                    yourliquidity:0,
                    apr:19.81,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x891c9B37177Bdf8Edc891119C9d8aEefDa9A5246.png',
                        symbol:'VROOM'
                    }
                },{
                    show:false,
                    liquidity:2007,
                    yourliquidity:0,
                    apr:23.13,
                    token0:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x4300000000000000000000000000000000000004.png',
                        symbol:'WETH'
                    },
                    token1:{
                        logoURI:'https://raw.githubusercontent.com/SpaceFinance/default-token-list/master/assets/0x5A7651Dd5C9d72fc4FDD4f9706193F33DFb4122d.png',
                        symbol:'ETHx'
                    }
                }
            ]
        }
    },
    async mounted() { 
        return
        this.tokenList = window.tokenlist
        await getAllPairsLength('0xCAb98fEd113d403EbD9E294D80Bf92E3f19ddD57').then(async index => {
                for (let i = 0; i < index; i++) {
                    if (i == 0 || i == 1 || i == 5 || i == 8) {  
                        
                    } else {
                        await getAllPairs('0xCAb98fEd113d403EbD9E294D80Bf92E3f19ddD57',i).then(token => {
                            var obj = {}
                            obj.token0 = {}
                            obj.token1 = {}
                            obj.contract = token
                            obj.index = i-0
                            getPairToken0(token).then(token0 => {
                                obj.token0.contract = token0
                                getTokenSymbol(token0).then(res => {
                                    obj.token0.symbol = res
                                })
                                this.tokenList.forEach((item,index) => {
                                    if(item.address.toUpperCase() == token0.toUpperCase()){
                                        obj.token0.logoURI = item.logoURI
                                    }
                                })
                                getPairToken1(token).then(token1 => {
                                    obj.token1.contract = token1
                                    getTokenSymbol(token1).then(res => {
                                        obj.token1.symbol = res
                                        console.log(obj)
                                    })
                                    this.tokenList.forEach((item,index) => {
                                        if(item.address.toUpperCase() == token1.toUpperCase()){
                                            obj.token1.logoURI = item.logoURI
                                        }
                                    })
                                    this.$axios.get('https://api.dexscreener.com/latest/dex/pairs/blast/'+token).then(info => {
                                        console.log(info)
                                        if(info){
                                            var liquidity = info.pairs[0].liquidity.usd
                                            var volume = info.pairs[0].volume.h24
                                            obj.apr = volume*0.3/100*365/liquidity*100*0.5*2
                                            obj.liquidity = info.pairs[0].liquidity.usd 
                                        } else {
                                            obj.apr = 0
                                            obj.liquidity = 0
                                        }
                                        if(this.userAddress.indexOf(0) != -1){
                                            getBalanceOf(token,this.userAddress).then(res => {
                                                // console.log(token,res,info.pair.priceUsd)
                                                if (res != 0) {
                                                    this.haveYuorLiquidity = true
                                                }
                                                gettotalSupply(token).then(total => {
                                                    // console.log(token,res/Math.pow(10,18),info.pairs[0].liquidity.usd,(total/1e18))
                                                    obj.yourliquidity = (res/Math.pow(10,18))*(info.pairs[0].liquidity.usd/(total/1e18))
                                                    this.poolsInfo.push(obj)
                                                    this.poolsInfo.sort((a,b) => {
                                                        if (a.liquidity > b.liquidity) { return -1;
                                                        } else if (a.liquidity < b.liquidity) { return 1;
                                                        } else { return 0; }
                                                    })
                                                })
                                                
                                            })
                                        } else {
                                            obj.yourliquidity = 0
                                            this.poolsInfo.push(obj)
                                            this.poolsInfo.sort((a,b) => {
                                                if (a.liquidity > b.liquidity) { return -1;
                                                } else if (a.liquidity < b.liquidity) { return 1;
                                                } else { return 0; }
                                            })
                                        }
                                    })
                                })
                            })
                        })
                    }
                } 
            })
        
            
    },
    methods: {
        
        goSwap () {
            window.open("https://swap.blaspace.io/#/swap","_self")
        }
    }
}
</script>